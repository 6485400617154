<template>
    <div>
      <div class="block py-2 rounded px-3 d-flex" style="background-color: #dfe1e2;">
        <h5 class="me-3 rounded-circle" v-if="status"
          style="width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; background-color: #ccc; color: #fff; border-radius: 50%;">
          {{ userData.profile ? userData.profile[0].toUpperCase() : 'N/A' }}
  
        </h5>
        &nbsp;<p class="mt-2">{{ userData.email }}</p>
      </div>
      <div class="col-12 col-md-12 col-xl-12">
  
        <div class="chat" v-if="status">
          <!-- Messages Area -->
          <div class="chat-messages" ref="messagesContainer">
  
            <!-- Message -->
            <div v-for="msg in messages" :key="msg.chat_id"
              :class="['message', msg.sent_by_me ? 'message-right' : 'message-left']">
              <p>{{ msg.content }}</p>
              <span class="message-time">{{ msg.timestamp }}</span>
            </div>
  
  
          </div>
  
          <!-- Input Area -->
          <div class="chat-input d-flex">
            <input type="text" class="form-control me-2" placeholder="Type your message..." v-model="newMessage">
            <button class="btn btn-primary" @click="sendMessage">Send</button>
          </div>
        </div>
        <div class="chat" v-else>
          <div class="chat-messages  row-12 py-5">
            <center>
              <h4 class="py-5">Start to chat</h4>
            </center>
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import authAxios from "../../authAxios.js";
  export default {
    props: {
  
      openChats: {
        type: String, // it's an ID of type string
        required: true
      },
      userData: {
        type: Object,
        required: true
      },
      status: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        newMessage: '',
        replyMessage: '',
        messages: [],
        currentMessageIndex: null,
        replyDialogVisible: false,
      };
    },
    methods: {
      async fetchChatData() {
        // chat-history
        const response = await authAxios.post("chat/chat-history", { chat_id: this.openChats });
  
        if (response.data.status === "SUCCESS") {
          this.messages = response.data.payload;
        }
      },
      async sendMessage() {
        // reciever_mail
        // messageText
        const recieverMail = this.userData.email;
        const messageText = this.newMessage.trim();
        if (messageText) {
          this.messages.push({
            sent_by_me: true,
            // subject: 'New Message',
            content: messageText,
            timestamp: new Date().toLocaleString(),
          });
          const response = await authAxios.post("chat/send-message", { reciever_mail: recieverMail, messageText: this.newMessage });
          this.newMessage = ''; // Clear input after sending
          this.scrollToBottom(); // Scroll to bottom after sending a new message
        }
      },
      scrollToBottom() {
        const messagesContainer = this.$refs.messagesContainer;
        if (messagesContainer) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      },
    },
    watch: {
      // Watch for changes in the messages array and scroll to the bottom automatically
      messages() {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      // Watch for changes in openChats prop to fetch new data
      openChats(newId, oldId) {
        if (newId !== oldId) {
          this.fetchChatData();
        }
      },
      async newMessage(newValue) {
        if (newValue === "") {
          await this.fetchChatData();
        }
      },
    },
    mounted() {
      // Scroll to bottom when the component is mounted
      this.scrollToBottom();
  
      // Fetch chat data when the component is first mounted
      this.fetchChatData();
    },
  };
  </script>
  
  <style scoped>
  .chat {
    display: flex;
    flex-direction: column;
    height: 70vh;
    /* Chat area height */
    max-height: 80vh;
    overflow: hidden;
  }
  
  .chat-messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    overflow-y: auto;
    flex-grow: 1;
    scroll-behavior: smooth;
  }
  
  .message {
    max-width: 70%;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .message-left {
    background-color: #e9ecef;
    align-self: flex-start;
  }
  
  .message-right {
    background-color: #0d6efd;
    color: #fff;
    align-self: flex-end;
  }
  
  .message-time {
    font-size: 0.75rem;
    margin-top: 5px;
    opacity: 0.6;
    align-self: flex-end;
  }
  
  .chat-input {
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    display: flex;
    gap: 10px;
  }
  
  .chat-input input {
    flex-grow: 1;
  }
  
  .chat-input button {
    flex-shrink: 0;
  }
  </style>
  