<template>
    <div class="px-4 mt-3 row g-3" style="height:700px;"> <!-- Changed g-2 to g-3 for more spacing -->
        <!-- Sidebar Section -->
        <div class="mt-4 col-12 col-md-6 col-xl-3 card"> <!-- Adjusted column width for responsiveness -->
            <div class="px-3 py-2 -mt-4">
                <!-- Search Input -->
                <input type="text" class="form-control mt-3" placeholder="Search..." />
            </div>
            <!-- Scrollable List -->
            <ul class="list-group list-group-flush flex-grow-1 overflow-auto">
                <!-- User List Items -->
                <li v-for="user in users" :key="user.chat_id" class="list-group-item d-flex align-items-center"
                    @click="openMessage(user.chat_id, user)">
                    <h5 class="me-3 rounded-circle"
                        style="width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; background-color: #ccc; color: #fff; border-radius: 50%;">
                        {{ user.profile ? user.profile[0].toUpperCase() : 'N/A' }}
                    </h5>

                    <div>
                        <strong>{{ user.email }}</strong>
                        <small class="d-block text-muted">{{ user.sent_time }}</small>
                    </div>
                </li>
            </ul>
        </div>
        <!-- Main Content Section -->
        <div class="col-12 col-md-6 col-xl-9 mt-4">
            <div class="h-100 card">
                <commPage :openChats="chat_id" :status="status" :userData="userData" />
            </div>
        </div>
    </div>
</template>
<script>
import commPage from "./commPage.vue";
import authAxios from "../../authAxios.js";
//   import { showSuccessToast, showErrorToast } from '../../utils/';

export default {
    name: "ProfileOverview",
    components: {
        commPage,
    },
    data() {
        return {
            recipientEmail: "",
            users: [],
            status: false,
            chat_id: null,
            userData: {},
        };
    },

    created() {
        this.getRecipient();
    },
    methods: {
        // async addRecipient() {
        //     try {
        //         // Disable the submit button to prevent multiple submissions
        //         const submitButton = document.getElementById('submit-button');
        //         const closeButton = document.getElementById('close-button');
        //         submitButton.disabled = true;

        //         // Send the recipient email to the API
        //         const response = await authAxios.post("chat/new-reciepent", {
        //             reciever_mail: this.recipientEmail,
        //         });

        //         if (response.data.status === "SUCCESS") {
        //             // Close the modal and show a success toast
        //             closeButton.click();
        //             // showSuccessToast(response.data.payload);
        //         } else {
        //             // Show an error toast if the response status is not SUCCESS
        //             // showErrorToast(response.data.payload || 'Failed to add recipient');
        //         }

        //         // Clear the input field
        //         this.recipientEmail = "";
        //     } catch (error) {
        //         // Handle errors gracefully and show an error toast
        //         const errorMessage = error.response?.data?.message || 'Failed to add recipient';
        //         //   showErrorToast(errorMessage);
        //     } finally {
        //         // Ensure the submit button is re-enabled
        //         submitButton.disabled = false;
        //     }
        // },
        async getRecipient() {
            // list the recipient email to the API
            const response = await authAxios.post("chat/get-engineer");

            if (response.data.status === "SUCCESS") {
                this.users = response.data.payload;
            }
        },
        openMessage(chatMessagesId, user) {
            this.chat_id = chatMessagesId;
            this.status = true;
            this.userData = user;
        },

    },

};
</script>
<style scoped>
.list-group-item {
    cursor: pointer;
}

.list-group-item img {
    object-fit: cover;
}

.list-group-item:hover {
    background-color: #f8f9fa;
}
</style>