<template>
    <div v-if="isOpen" class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <button class="close-btn" @click="close">X</button>
  
        <!-- Title -->
        <h2 class="modal-title">Transaction Details</h2>
  
        <form @submit.prevent="submitForm" class="mt-4 px-4">
          <div class="form-grid">
            <div class="form-group">
              <label for="field1">Bill Number</label>
              <input type="text" id="field1" :value="billPayload.field1" ref="bill_number" class="small-input" disabled />
            </div>
  
            <div class="form-group">
              <label for="field2">Client</label>
              <input type="text" id="field2" :value="billPayload.field2" ref="client" class="small-input" disabled />
            </div>
  
            <div class="form-group">
              <label for="field3">Project Name</label>
              <input type="text" id="field3" :value="billPayload.field3" ref="projectname" class="small-input" disabled />
            </div>
  
            <div class="form-group">
              <label for="field4">Payment Method</label>
              <select id="field4" v-model="billPayload.field4" ref="method" class="small-input">
                <option value="">Select Payment Method</option>
                <option value="Cash">Cash</option>
                <option value="Credit Card">Credit Card</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Mobile Money">Mobile Money</option>
              </select>
            </div>
  
            <div class="form-group">
              <label for="field5">Amount</label>
              <input type="number" id="field5" v-model="formattedAmount" ref="amount" class="small-input" step="any"  />
            </div>
  
            <div class="form-group">
              <label for="field7">Payment Status</label>
              <input type="text" id="field7" :value="billPayload.field7" ref="status" class="small-input" disabled />
            </div>
            <div class="form-group">
              <label for="field7">Phone Number</label>
              <input 
              type="tel" 
              v-model="phoneNumber" 
              ref="phone_number" 
              class="small-input" 
              placeholder="(XXX) XXX-XXXX" 
            
              maxlength="14"
            />
            </div>
          </div>
  
          <!-- Right-aligned button -->
          <div class="button-container">
            <button type="submit" class="submit-btn">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import customAxios from '@/authAxios.js';
  import {mapActions } from "vuex";
//   import {}
  export default {
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      billPayload: {
        type: Object,
        required: true,
      },
    },
    computed: {
      formattedAmount() {
        return this.billPayload.field5.replace(/^UGX:\s*/, '');
      },
    },
    data() {
      return {
        // phoneNumber:'',
      };
    },
    methods: {
      ...mapActions("flutterwave", ["openCheckout", "closeModal"]),
      close() {
        this.$emit("close");
      },
      async submitForm() {
  
  
        let body = {
          bill_number: this.$refs.bill_number.value,
          client: this.$refs.client.value,
          project_name: this.$refs.projectname.value,
          method: this.$refs.method.value,
          amount: this.$refs.amount.value,
          amount: this.$refs.amount.value,
          phone_number: this.$refs.phone_number.value
        }
        try {
            await customAxios.post('pay_bill', body).then((res) => {
          if (res.data.status === 'success') {
  
            this.openCheckout(res.data.meta.authorization.redirect);
  
          }
        }) 
        } catch (error) {
            console.log(error.response.data.error)
            alert(error.response.data.error)
        }
        
      },
    },
  };
  </script>
  
  
  <style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .modal-title {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .small-input {
    padding: 5px;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    text-align: right;
    margin-top: 20px;
  }
  
  .submit-btn {
    padding: 8px 16px;
    background-color: #ffc107;
    /* Yellow color */
    color: #333;
    /* Dark text color for contrast */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .submit-btn:hover {
    background-color: #e0a800;
    /* Slightly darker yellow on hover */
  }
  
  
  @media (max-width: 600px) {
    .form-grid {
      grid-template-columns: 1fr;
    }
  }
  </style>
  