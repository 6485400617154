import axios from 'axios';

const authAxios = axios.create({
  baseURL: 'https://syt.yolumsltd.com/api/', // Set your base URL here
  // baseURL: 'http://127.0.0.1:8000/api/',
});

authAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');

  // Set the Authorization header
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Check if the data is FormData
  if (config.data instanceof FormData) {
    // Append branch to FormData
    config.data.append('branch', branch);
  } else {
    // If not FormData, merge the branch normally
    config.data = {
      ...config.data,
      branch: branch,
    };
  }

  return config;
});

export default authAxios;
