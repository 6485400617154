import { toRaw } from 'vue';
export default {
    namespaced: true, // Ensure this is set to true
    state: {
      isModalOpen: false,
      isCheckoutModalOpen: false,
      modalPageUrl: '',
      billPayload: {}
    },
    mutations: {
      openModal(state, payload) {
      
        state.billPayload = toRaw(payload).node.data;
        
        state.isModalOpen = true;
        // state.isCheckoutModalOpen = true;
    
      },
      openCheckout(state, pageUrl) {
        
        state.isCheckoutModalOpen = true;
        state.modalPageUrl = pageUrl|| '';
      },
      closeModal(state) {
        state.isModalOpen = false;
        state.isCheckoutModalOpen = false;
        state.modalPageUrl = '';
        state.billPayload = {};
     
      },
      closeCheckoutModal(state) {
        state.isCheckoutModalOpen = false;
        state.modalPageUrl = '';
        state.billPayload = {};
      },
    },
    actions: {
      openModal({ commit }, payload) {
        
        commit("openModal", payload);

      },
      openCheckout({ commit }, payload) {

        commit("openCheckout", payload);
      },
      closeModal({ commit }) {
        commit("closeModal");
      },
      // closeCheckoutModal({ commit }) {
      //   commit("closeCheckoutModal");
      // },
    },
  };
  