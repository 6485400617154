<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
import CategoriesList from "./components/CategoriesList.vue";
import ProjectDashTable from "./components/ProjectDashTable.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

const sales = {
  us: {
    country: "United States",
    sales: 2500,
    value: "$230,900",
    bounce: "29.9%",
    flag: US,
  },
  germany: {
    country: "Germany",
    sales: "3.900",
    value: "$440,000",
    bounce: "40.22%",
    flag: DE,
  },
  britain: {
    country: "Great Britain",
    sales: "1.400",
    value: "$190,700",
    bounce: "23.44%",
    flag: GB,
  },
  brasil: {
    country: "Brasil",
    sales: "562",
    value: "$143,960",
    bounce: "32.14%",
    flag: BR,
  },
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Projects" :value=totalProjects description="<span
                class='text-sm font-weight-bolder text-warning'
                >No of projects</span>" :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Pending Orders" :value=pendingOrders description="<span
                class='text-sm font-weight-bolder text-warning'
                >No of Orders pending</span>" :icon="{
                  component: 'ni ni-world',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Approved Orders" :value=deliveredOrders description="<span
                class='text-sm font-weight-bolder text-warning'
                >Orders approved</span> " :icon="{
                  component: 'ni ni-paper-diploma',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Un Paid Invoices" :value=totalAmountUnpaid description="<span
                class='text-sm font-weight-bolder text-warning'
                >unpaid invoices</span>" :icon="{
                  component: 'ni ni-cart',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5">
            <carousel />
          </div>
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart id="chart-line" title="Project Overview" description="<i class='fa fa-arrow-up text-warning'></i>
    <span class='font-weight-bold'>progress</span> in 2021" :chart="chartData" />
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import authAxios from "../authAxios";
export default {
  data() {
    return {
      totalProjects: 0,          // Total number of projects for the client
      pendingOrders: 0,          // Number of orders that are pending
      deliveredOrders: 0,        // Number of orders that have been delivered
      totalAmountDue: 0,         // Total amount billed for the client
      totalAmountPaid: 0,        // Total amount paid by the client
      totalAmountUnpaid: 0,
      chartData: {
        labels: [], // Initialize labels for months
        datasets: [
          {
            label: 'Total Projects',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            data: [], // Total projects per month
          },
          {
            label: 'Completed Projects',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            data: [], // Completed projects per month
          },
          {
            label: 'In-progress Projects',
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
            data: [], // In-progress projects per month
          },
          {
            label: 'Total Budget',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            data: [], // Total budget per month
          },
          {
            label: 'Total Expenditure',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            data: [], // Total expenditure per month
          },
        ],
      },
    }
  },
  created() {
    this.getStatics();
    // this.statGraph();
  },
  methods: {
    async getStatics() {
      const resp = await authAxios.post('client-dashboard-stat').then(data => {

        const safeNumber = (value) => (Number(value) || 0);

        let stat = data.data.payload;
        this.totalProjects = safeNumber(stat.total_projects);          // Total number of projects for the client
        this.pendingOrders = safeNumber(stat.pending_orders);          // Number of orders that are pending
        this.deliveredOrders = safeNumber(stat.delivered_orders);        // Number of orders that have been delivered
        // console.log('deliveredOrders',this.deliveredOrders);
        this.totalAmountDue = safeNumber(stat.total_bill);         // Total amount billed for the client
        this.totalAmountPaid = safeNumber(stat.total_paid);        // Total amount paid by the client
        this.totalAmountUnpaid = safeNumber(stat.unpaid);

      })
    },
    // async statGraph() {
    //   const resp = await authAxios.post('client_statictics').then(data => {
    //     console.log('DATA STAT', data.data.payload)
    //   })
    // },
    async fetchProjectStats() {
      try {
        const response = await authAxios.post('client_statictics');
        const stats = response.data.payload; // Array of statistics for each month
        console.log('STAR===', stats)
        // Extracting data for each dataset
        this.chartData.labels = stats.map(item => item.month);
        this.chartData.datasets[0].data = stats.map(item => item.totalProjects);      // Total Projects
        this.chartData.datasets[1].data = stats.map(item => item.completedProjects);  // Completed Projects
        this.chartData.datasets[2].data = stats.map(item => item.inProgressProjects); // In-progress Projects
        this.chartData.datasets[3].data = stats.map(item => item.totalBudget);        // Total Budget
        this.chartData.datasets[4].data = stats.map(item => item.totalExpenditure);   // Total Expenditure
      } catch (error) {
        console.error('Failed to fetch project statistics:', error);
      }
    },
  },
  mounted() {
    this.fetchProjectStats();
  },
}
</script>
